<script>
import DefaultCard from "../default/DefaultCard";
import ElectricityMeterCardHeader from "./ElectricityMeterCardHeader";
import ElectricityMeterCardMetricList from "./ElectricityMeterCardMetricList";

export default {
    name: "ElectricityMeterCard",
    extends: DefaultCard,
    components: {
        HeaderComponent: ElectricityMeterCardHeader,
        MetricList: ElectricityMeterCardMetricList,
    },
}
</script>
