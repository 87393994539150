<template>
    <div>
        <div class="deviceCardMetricItem d-flex align-items-center justify-content-between w-100 font-weight-500"
             v-for="(metric, index) in deviceMetrics"
             style="height: 57px"
             :key="index">
            <div class="w-50 p-3">
                <div class="d-flex justify-content-start align-items-center">
                    <div class="pr-2">
                        <div class="pr-1">
                        <span v-if="metric.getPortIndex() !== null" class="metric-icon-index">
                            {{ metric.getPortIndex() + 1 }}
                        </span>
                            <Icon :icon-name="metric.getIcon()"
                                  width="23"
                                  height="23"
                                  :fill="metric.getColor()"/>
                        </div>
                    </div>
                    <div v-if="metric.getName().length > 37"
                         data-toggle="tooltip"
                         :title="metric.getName()">
                        {{ stringLimit(metric.getName(), 37) }}
                    </div>
                    <div v-else>
                        {{ metric.getName() }}
                    </div>
                </div>
            </div>
            <div v-if="metric.getSlug() === 'load_state'" class="d-flex align-items-center justify-content-center w-17 border-left h-100"
                 :class="{'bg-accident': metricHasAccident(metric)}">
                <div v-if="status !== undefined">
                    <div v-if="!status" class="text-danger text-center">
                        <i class="fas fa-power-off"></i>
                        {{ $t('Отключена') }}
                    </div>
                    <div v-if="status" class="text-success text-center">
                        <i class="fas fa-power-off"></i>
                        {{ $t('Включена') }}
                    </div>
                </div>
                <div v-else class="text-muted text-center">
                    N/A
                </div>
            </div>
            <div v-else class="d-flex align-items-center justify-content-center w-17 border-left h-100"
                 :class="{'bg-accident': metricHasAccident(metric)}">
                {{ getMetricLabel(metric) }}
            </div>
            <div class="w-33 px-3 border-left h-100 d-flex align-items-center justify-content-center">
                <ElectricityMeterCardButtons :deviceObject="deviceObject" :metric="metric" :status="status"/>
            </div>
        </div>
    </div>
</template>

<script>
import DefaultCardMetricList from "../default/DefaultCardMetricList";

import ElectricityMeterCardButtons from "./ElectricityMeterCardButtons";

export default {
    name: "ElectricityMeterCardMetricList",
    components: {ElectricityMeterCardButtons},
    extends: DefaultCardMetricList,
    computed: {
        status() {
            const value = parseInt(this.getMetricLabel(this.loadMetric, 0));
            if (value === 1) {
                return true;
            }
            if (value === 0) {
                return false;
            }
            return null;
        },
        loadMetric() {
            return this.deviceMetrics.find(metric => metric.getSlug() === 'load_state');
        },
    }
}
</script>
